<template>
  <div class="warp">
    <div class="top">
      <div class="top-s">
        <img v-if="imgUrl" :src="imgUrl" alt="">
        <p v-if="catename">{{catename}}</p>
      </div>
      <div class="top-x">{{remarks}}</div>
    </div>
    <div class="bottom" v-if="isShowList">
      <p @click="selectClick(item, index)"
        :style="valueIndex === index ? 'backgroundColor: #aac88c' : ''"
        v-for="(item, index) in itemList"
        :key="index">{{item.name}}</p>
    </div>
    <div class="bottom" v-else>
      <p>暂无分类</p>
    </div>
    <div class="btn">
      <van-button round block type="info" @click="nextStep">选择分类,下一步</van-button>
    </div>
    <van-dialog v-model="isShowDialog" title="标题" show-cancel-button @confirm="confirm">
      <van-field v-model="valueName" label="其它" placeholder="请输入其它" />
    </van-dialog>
  </div>
</template>

<script>
import { categoryId } from '@/api/notice.js'
import { Toast } from 'vant'
import { Dialog } from 'vant'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      isShowDialog: false,
      isShowList: true,
      imgUrl: '',
      catename: '',
      remarks: '',
      itemList: [],
      itemListArr: [],
      valueIndex: undefined,
      valueName: undefined
    }
  },
  created() {
    this.categoryId()
  },
  methods: {
    selectClick(item, index) { // 多选
      this.valueIndex = index
      if(this.itemListArr.length >= 1){
        this.itemListArr.shift(item)
        this.itemListArr.push(item)
      } else {
        this.itemListArr.push(item)
      }
      if (item.name == '其它') {
        this.isShowDialog = true
      }
    },
    confirm() {
      if (this.valueName) {
        this.itemListArr[0].nameToken = this.itemListArr[0].nameToken.split(' / ')[0] + ' / ' + this.valueName
      }
    },
    categoryId() { // 获取所有分类
      categoryId(this.$route.query.id).then(res => {
        if(res.data.code === 200) {
          this.itemList = res.data.data.children
          this.imgUrl = res.data.data.imageUrl
          this.catename = res.data.data.name
          this.remarks = res.data.data.remarks
        }
      })
    },
    nextStep() {
      if (this.itemListArr.length < 1) {
        return Toast('请选择子分类')
      }
      this.$router.push({path: 'Placeanorder', query: {itemList: this.itemListArr, categoryId: this.$route.query.id}})
    }
  }
}
</script>
<style lang="scss" scoped>
.warp {
  .top {
    padding: 15px 30px;
    .top-s {
      display: flex;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      img {
        width: 25px;
        margin-right: 10px;
      }
      p {
        font-weight: 600;
        font-size: 20px;
      }
    }
    .top-x {
      font-size: 14px;
      line-height: 23px;
    }
  }
  .bottom {
    background-color: #fff;
    p {
      line-height: 40px;
      text-align: center;
      font-size: 18px;
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
